<template>
  <div class="wrapper" v-if="isReady">
    <div class="content-wrapper">
      <div class="dashboard-content">test</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentView",
  components: {},
  data() {
    return {};
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isRulesReady() {
      return this.$store.getters["user/isRulesReady"];
    },
    isReady() {
      return this.$store.getters.isReady && this.contract && this.isRulesReady;
    }
  },
  watch: {},
  methods: {
    async fetchScreens() {
      if (!this.contract) return;
      this.$store.dispatch("dashboard/fetchScreens", {
        contract_id: this.contract.id
      });
    },
    async fetchEquipment() {
      if (!this.contract) return;
      this.$root.$store.dispatch("fetchEquipmentList");
    }
  },
  beforeCreate() {
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "/static/common/css/dashboard-printout.css";
    let head = document.getElementsByTagName("head")[0];
    head.appendChild(link);
    // validate screen type
    // app.__vue__.$store.getters["dashboard/screens"].filter(({public})=>public).map(({id})=>id)
  },
  beforeDestroy() {
    let head = document.getElementsByTagName("head")[0];
    head.childNodes.forEach((item) => {
      if (item.href && item.href.endsWith("dashboard-printout.css"))
        item.parentNode.removeChild(item);
    });
  },
  mounted() {}
};
</script>

<style scoped>
</style>

<style>
</style>
